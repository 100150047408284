import React, { useEffect, useState } from "react";
import "./SuccessPage.css";
import { PiSealCheckFill } from "react-icons/pi";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { FaUser } from "react-icons/fa6";
import { IoCall } from "react-icons/io5";
import { MdMarkEmailUnread } from "react-icons/md";
import { Avatar, Divider, List } from "antd";

const SuccessPage = () => {
  const { order_id } = useParams(); // Get order_id from URL
  const [orderDetails, setOrderDetails] = useState(null); // Store order details
  const [userDetails, setUserDetails] = useState(null); // Store user details

  // Fetch user details based on user_id
  const fetchUserDetails = async (user_id) => {
    try {
      const response = await axios.get(
        `https://www.skdm.in/server/royal/user.php?action=accountFetchwithid&user_id=${user_id}`
      );
      console.log("User API Response:", response.data); // Log the response
      if (response.data && response.data.user) {
        setUserDetails(response.data.user);
      }
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

  // Fetch order details and user details
  const fetchOrderDetails = async () => {
    try {
      const response = await axios.get(
        `https://www.skdm.in/server/royal/order_list.php?action=orderFetch&order_id=${order_id}`
      );
      console.log("Order API Response:", response.data); // Log the response
      if (response.data && response.data.order) {
        setOrderDetails(response.data.order);
        const user_id = response.data.order.user_id;
        if (user_id) {
          fetchUserDetails(user_id); // Fetch user details with user_id
        }
      }
    } catch (error) {
      console.error("Error fetching order details:", error);
    }
  };

  useEffect(() => {
    fetchOrderDetails();
    localStorage.clear(); // Clear cart data from local storage
  }, [order_id]);

  return (
    <>
      <section className="SuccessPage-Section-con">
        <div className="container">
          <div className="SuccessPage-Section">
            <div className="SuccessPage-Section-Details-con">
              <div className="order-Success-con">
                <div className="order-Success-con-icons">
                  <PiSealCheckFill />
                  <h2>Order Confirmed</h2>
                  <p>
                    Congratulations! Your booking with A Royal Resort has
                    been successfully confirmed. Thank you for choosing us!
                  </p>
                </div>

                <div className="Order-Success-Details-con-Main">
                  <div className="Order-Success-Details-con-Main-head">
                    <div className="Order-Success-List-head">
                      <h3>
                        Booking ID: <span>{orderDetails?.order_id}</span>
                      </h3>
                    </div>

                    <div className="Order-Success-List-head">
                      <h3>
                        Status: <span>{orderDetails?.order_status}</span>
                      </h3>
                    </div>
                    <div className="Order-Success-List-head">
                      <h3>
                        Check In: <span>{orderDetails?.check_in}</span>
                      </h3>
                    </div>
                    <div className="Order-Success-List-head">
                      <h3>
                        Check Out: <span>{orderDetails?.check_out}</span>
                      </h3>
                    </div>

                    <div className="Order-Success-List-head">
                      <h3>
                        Amount: <span>₹{orderDetails?.order_amount}</span>
                      </h3>
                    </div>
                  </div>

                  <div className="Order-Success-Details-con">
                    <div className="Order-Success-Details-add">
                      <h1>Booking Detail :</h1>
                      <div className="Order-Success-Details-name-contact">
                        <h2 className="d-flex align-items-center">
                          <FaUser />
                          &nbsp;&nbsp; {userDetails?.user_name || "N/A"}
                        </h2>
                        <span>|</span>
                        <h2 className="d-flex align-items-center">
                          <IoCall />
                          &nbsp;&nbsp; {userDetails?.user_mobile || "N/A"}
                        </h2>
                        <span>|</span>
                        <h2 className="d-flex align-items-center">
                          <MdMarkEmailUnread />
                          &nbsp;&nbsp; {userDetails?.user_email || "N/A"}
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>

               
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SuccessPage;
