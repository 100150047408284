import logo from './logo.svg';
import './App.css';
import Header from './Component/Navbar/Header';
import Home from './Pages/Home';
import About from "./Pages/About";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Rooms from './Pages/Rooms';
import ReviewBooking from './Component/BookingReview/ReviewBooking';
import LikePay from './Component/Pay/LikePay';
import Contact from './Pages/Contact';
import Gallery from './Pages/Gallery';
import ForgotPass from './Component/Password/ForgotPass';
import BookingConfirm from './Component/Congratulation/BookingConfirm';
import RoomDetailsPage from './Component/RoomDetails/RoomDetailsPage';
import Policy from './Pages/Policy';
import SuccessPage from './Component/Congratulation/SuccessPage';
import Account from './Pages/Account';
import Profiles from "./Component/profile/Profiles";
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        {/* <Header /> */}
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/top-resort-with-rooms-mahabaleshwar' element={<Rooms />} />
          <Route path='/premium-villa-mahabaleshwar' element={<About />} />
          <Route path='/family-resort-mahabaleshwar' element={<Gallery />} />
          <Route path='/resort-near-mahabaleshwar' element={<Contact />} />
          {/* <Route path='/Blog' element={<Blog />} /> */}
          <Route path='/booking/:room_id' element={<ReviewBooking />} />
          <Route path='/pay' element={<LikePay />} />
          <Route path='/profile' element={<Profiles />} />
          {/* <Route path='/sign-up' element={<SignUp />} /> */}
          <Route path='/forgotpassword' element={<ForgotPass />} />
          <Route path='/BookingConfirm' element={<BookingConfirm />} />
          <Route path='/RoomDetailsPage/:room_id' element={<RoomDetailsPage />} />
          <Route path='/policy' element={<Policy/>}/>
          <Route path='/success/:order_id' element={<SuccessPage/>}/>
          <Route path='/failed/:order_id' element={<failpage/>}/>
          <Route path="/account/:page" element={<Account />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
