import React from 'react';
import "../Amenities/AmenitiesSec.css";
import cctv from "../../Images/Amenitie/cctv.png";
import security from "../../Images/Amenitie/guard.png";
import parking from '../../Images/Amenitie/parking.png';
import gamezone from "../../Images/Amenitie/games.png";
import { FaCheckDouble } from "react-icons/fa6";
import royal from "../../Images/Aayush Images/royal.png";
const AmenitiesSec = () => {
    return (
        <>
            <section className='Home-Section-Amenities-con'>
                <div className='container'>
                    <div className='Home-Aminities-Section-Head'>
                        <h2>
                            Aminities
                            <div className='HeadImageoverlay'>
                                <img src={royal} alt='royal resort in mahabaleshwar' />
                            </div>
                        </h2>
                    </div>
                    <div className='Home-Section-Amenities-Main-Con'>
                        <div className='row'>
                            <div className='col-md-4'>
                                <div className='Home-Section-Amenities-icon-name-con'>
                                    <div className='Home-Section-amenities-icon-details-con'>
                                        <div className='Home-Section-Amenities-icon-name'>
                                            <div className='Home-Section-amenities-icon-con'>
                                                <div className='Home-Section-Amenities-icon'>
                                                    <img src={cctv} alt='Resort in Mahabaleshwar'/>
                                                </div>
                                            </div>
                                            <div className="Home-Section-Aminites-icon-name">
                                                <h3>CCTV</h3>
                                            </div>
                                        </div>
                                        <div className='Home-Section-Amenities-icon-name'>
                                            <div className='Home-Section-amenities-icon-con'>
                                                <div className='Home-Section-Amenities-icon'>
                                                    <img src={security} alt='royal Best Resort in Mahabaleshwar'/>
                                                </div>
                                            </div>
                                            <div className="Home-Section-Aminites-icon-name">
                                                <h3>Security</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='Home-Section-Amenities-icon-name-con'>
                                    <div className='Home-Section-amenities-icon-details-con'>
                                        <div className='Home-Section-Amenities-icon-name'>
                                            <div className='Home-Section-amenities-icon-con'>
                                                <div className='Home-Section-Amenities-icon'>
                                                    <img src={parking} alt='Best Resort near Mahabaleshwar'/>
                                                </div>
                                            </div>
                                            <div className="Home-Section-Aminites-icon-name">
                                                <h3>Secure Parking</h3>
                                            </div>
                                        </div>
                                        <div className='Home-Section-Amenities-icon-name'>
                                            <div className='Home-Section-amenities-icon-con'>
                                                <div className='Home-Section-Amenities-icon'>
                                                    <img src={gamezone} alt='best villa in mahabaleshwar'/>
                                                </div>
                                            </div>
                                            <div className="Home-Section-Aminites-icon-name">
                                                <h3>Game Zone</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-8'>
                                <div className='Home-Section-Aminites-Details-con'>
                                    <p>Royal Resort offers luxurious amenities designed for ultimate relaxation and comfort. Enjoy spacious rooms with stunning views, a fully-equipped fitness center, rejuvenating spa services, outdoor pools, and fine dining options. With personalized concierge services, event spaces, and recreational activities, every guest experience is tailored to perfection. Whether for leisure or business, Royal Resort ensures a memorable stay.</p>                                    <div className='Home-Section-Aminites-Details-point-con'>
                                        <div className='row row-cols-lg-2 row-cols-sm-2 row-cols-1'>
                                            <div className='col'>
                                                <div className='Home-Section-Aminites-text-con'>
                                                    <span><FaCheckDouble /></span>
                                                    <h3>ON-CALL MEDICAL SERVICE</h3>
                                                </div>
                                            </div>
                                            <div className='col'>
                                                <div className='Home-Section-Aminites-text-con'>
                                                    <span><FaCheckDouble /></span>
                                                    <h3>TELEVISION WITH HD SET TOP BOX</h3>
                                                </div>
                                            </div>
                                            <div className='col'>
                                                <div className='Home-Section-Aminites-text-con'>
                                                    <span><FaCheckDouble /></span>
                                                    <h3>AIR CONDITION (AC)</h3>
                                                </div>
                                            </div>
                                            <div className='col'>
                                                <div className='Home-Section-Aminites-text-con'>
                                                    <span><FaCheckDouble /></span>
                                                    <h3>GAME ROOM</h3>
                                                </div>
                                            </div>
                                            <div className='col'>
                                                <div className='Home-Section-Aminites-text-con'>
                                                    <span><FaCheckDouble /></span>
                                                    <h3>SOFA</h3>
                                                </div>
                                            </div>
                                            <div className='col'>
                                                <div className='Home-Section-Aminites-text-con'>
                                                    <span><FaCheckDouble /></span>
                                                    <h3>TEA/COFFEE MAKER</h3>
                                                </div>
                                            </div>
                                            <div className='col'>
                                                <div className='Home-Section-Aminites-text-con'>
                                                    <span><FaCheckDouble /></span>
                                                    <h3>PACKAGE DRINKING WATER</h3>
                                                </div>
                                            </div>
                                            <div className='col'>
                                                <div className='Home-Section-Aminites-text-con'>
                                                    <span><FaCheckDouble /></span>
                                                    <h3>BATHROOM AND TOILETS WITH ALL SUPPLIES & AMENITIES & AMINITIES</h3>
                                                </div>
                                            </div>
                                            <div className='col'>
                                                <div className='Home-Section-Aminites-text-con'>
                                                    <span><FaCheckDouble /></span>
                                                    <h3>HOT/COLD WATER</h3>
                                                </div>
                                            </div>
                                            <div className='col'>
                                                <div className='Home-Section-Aminites-text-con'>
                                                    <span><FaCheckDouble /></span>
                                                    <h3>MUSIC SYSTEM</h3>
                                                </div>
                                            </div>
                                            <div className='col'>
                                                <div className='Home-Section-Aminites-text-con'>
                                                    <span><FaCheckDouble /></span>
                                                    <h3>AN INVERTER BACKUP</h3>
                                                </div>
                                            </div>
                                            <div className='col'>
                                                <div className='Home-Section-Aminites-text-con'>
                                                    <span><FaCheckDouble /></span>
                                                    <h3>WIFI@NO COST</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AmenitiesSec