import React, { useEffect, useMemo, useState } from "react";
import "../Order/MyOrder.css";

import { Drawer } from "antd";
import { Button, ConfigProvider, Popover, Segmented } from "antd";
import axios from "axios";
import { Pagination } from "antd";
import moment from "moment";
const MyOrder = () => {
  const tokenid = sessionStorage.getItem("access_token");
  const storedProfileData = JSON.parse(sessionStorage.getItem('profile'));
  const userid = storedProfileData.userid;
  console.log("token id ",tokenid);
  console.log("User  id  is  this",userid);
  const [open, setOpen] = useState(false);
  const [orderDetails, setOrderDetails] = useState(null);
  const [userDetail, setuserDetails] = useState(null);
  const showDrawer = (order) => {
    setOrderDetails(order);
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  console.log("orderDetails", orderDetails);

  const Content = ({ address_1, address_2, state, city, pincode }) => (
    <div className="Order-Details-Address">
      <p>{address_1}</p>
      <p>{address_2}</p>
      <p>
        {city}, {state} {pincode}
      </p>
    </div>
  );
  // const text = <span>Title</span>;
  const [arrow, setArrow] = useState("Show");
  const mergedArrow = useMemo(() => {
    if (arrow === "Hide") {
      return false;
    }
    if (arrow === "Show") {
      return true;
    }
    return {
      pointAtCenter: true,
    };
  }, [arrow]);

  const [yourOrderProduct, setYourOrderProduct] = useState(null);
  const [yourOrder, setYourOrder] = useState(null);
  const [currentPage, setCurrentPage] = useState(1); // State to manage current page
  const itemsPerPage = 4; // Number of items to display per page

  console.log("yourOrderProduct", yourOrderProduct);
  console.log("view", yourOrderProduct);
  console.log("userdata", userDetail);

  useEffect(() => {
    fetchData(userid);
  }, [tokenid]);

  // const fetchUserData = async () => {
  //   try {
  //     const response = await axios.get(
  //       `https://riverestates.in/api/user.php?action=fetch&token=${tokenid}`
  //     );
  //     const userId = response.data.user.user_id;
  //     if (userId) {
  //       setuserDetails(userId);
  //       fetchData(userId);
  //     } else {
  //       console.error("User ID not found in response:", response.data);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };

  const fetchData = async (userId) => {
    try {
      const response = await axios.get(
        `https://advikagreencrestresort.in/api/order_list.php?action=fetchOrderByUserId&user_id=1`
      );
      const responseb=response;
      
      console.log("This data is to be displayed",responseb)
  
      // No need to use orders[0], just iterate over the orders array directly
      const filteredOrders = response.data.orders.filter(
        (order) => order.user_id === String(userId)
      );
  
      setYourOrderProduct(filteredOrders);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  // Logic to paginate the orders
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems =
    yourOrderProduct &&
    yourOrderProduct.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <>
      <section className="MyOrder-Section">
        <div className="MyOrder-Section-Heading">
          <h1>All Order</h1>
        </div>

        <div className="MyOrder-Section-con">
          {currentItems === null ? (
            <p>Looks like you haven't placed an order</p>
          ) : (
            <>
              {currentItems &&
                currentItems.map((elem, index) => {
                  const {
                    User_Email,
                    user_name,
                    User_Phone,
                    address_1,
                    address_2,
                    address_id,
                    alternative_number,
                    city,
                    state,
                    check_in,
                    order_amount,
                    order_id,
                    order_list_id,
                    order_status,
                    payment_mode,
                    pincode,
                    products,
                    tracking_id,
                    user_id,
                  } = elem;
                  return (
                    <>
                      <div className="Product-addedCart-Side-Bar orderlistmaincon">
                        <div className="Orderlist-Details-Head-Con">
                          <div className="row">
                            <div className="col-md-3 col-sm-4 col-12">
                              <div className="OrderList-Details-Date">
                                <h2>Booking</h2>
                                <h3>Check In:{elem.check_in}</h3>
                                <h3>Check Out:{elem.check_out}</h3>

                              </div>
                            </div>
                            <div className="col-md-2 col-sm-4 col-6">
                              <div className="OrderList-Details-Date">
                                <h2>Total</h2>
                                <h3>Rs. {elem.order_amount}</h3>
                              </div>
                            </div>
                            <div className="col-md-2 col-sm-4 col-6">
                              <div className="OrderList-Details-Date">
                                <h2>Name</h2>
                                <h3>
                                  <Popover
                                    placement="bottom"
                                    content={
                                      <Content
                                        address_1={address_1}
                                        address_2={address_2}
                                        city={city}
                                        state={state}
                                        pincode={pincode}
                                      />
                                    }
                                    arrow={mergedArrow}
                                  >
                                    <Button>{user_name}</Button>
                                  </Popover>
                                </h3>
                              </div>
                            </div>
                            <div className="col-md-5">
                              <div className="OrderList-Details-Date orderidcon">
                                <h2>ORDER {order_id}</h2>
                                <div className="OrderList-Status-Details">
                                  <div className="OrderList-Status-Details-btn">
                                    {order_status}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {products &&
                          products.map((elem) => {
                            const {
                              Category_Id,
                              Product_Code,
                              Product_Desc,
                              Product_Height,
                              Product_Id,
                              Product_Name,
                              Product_Width,
                              Size_Id,
                              color_code,
                              color_name,
                              created_at,
                              product_color_id,
                              product_feature,
                              product_price,
                              product_qty,
                              image,
                            } = elem;
                            return (
                              <>
                                <div className="Product-addedCart-Side-details orderList-con">
                                  <div className="Product-details-addedCart-price-name order-details-addedCart-price-name">
                                    <h3>
                                      <span>Name : </span>
                                      {Product_Name}
                                    </h3>
                                    <h3>
                                      <span>Quantity : </span>
                                      {product_qty}
                                    </h3>
                                    <h3>
                                      <span>Price : </span>
                                      {product_price}/- Rs
                                    </h3>
                                  </div>
                                </div>
                              </>
                            );
                          })}
                        <div className="Product-details-addedCart-cancel-btn">
                          <div className="Product-details-addedCart-cancel-btn-con OrderProduct-Btn-Con">
                            <button onClick={() => showDrawer(elem)}>
                              View order details
                            </button>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
            </>
          )}
          <Pagination
            defaultCurrent={1}
            total={yourOrderProduct ? yourOrderProduct.length : 0} // Total number of items
            pageSize={itemsPerPage} // Items per page
            onChange={(page) => setCurrentPage(page)} // Function to handle page change
          />
        </div>
      </section>

      <Drawer title="Order Details" onClose={onClose} open={open}>
        {orderDetails && (
          <section className="Order-Details-Drawer">
            <div className="Order-Details-Drawer-Details-order-Id">
              <div className="Order-Details-First-Part">
                <div className="row">
                  <div className="col-5">
                    <div className="Order-DetailsName">
                      <h2>Check In</h2>
                    </div>
                  </div>
                  <div className="col-7">
                    <div className="Order-Details-dec">
                      <h3>{orderDetails.check_in}</h3>
                    </div>
                  </div>
                </div>
              </div>

              <div className="Order-Details-First-Part">
                <div className="row">
                  <div className="col-5">
                    <div className="Order-DetailsName">
                      <h2>Check Out</h2>
                    </div>
                  </div>
                  <div className="col-7">
                    <div className="Order-Details-dec">
                      <h3>{orderDetails.check_out}</h3>
                    </div>
                  </div>
                </div>
              </div>

              <div className="Order-Details-First-Part">
                <div className="row">
                  <div className="col-5">
                    <div className="Order-DetailsName">
                      <h2>Booking Id</h2>
                    </div>
                  </div>
                  <div className="col-7">
                    <div className="Order-Details-dec">
                      <h3>{orderDetails.order_id}</h3>
                    </div>
                  </div>
                </div>
              </div>

              <div className="Order-Details-First-Part">
                <div className="row">
                  <div className="col-5">
                    <div className="Order-DetailsName">
                      <h2>Total</h2>
                    </div>
                  </div>
                  <div className="col-7">
                    <div className="Order-Details-dec">
                      <h3>Rs. {orderDetails.total_amount}</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="Order-Details-Drawer-Details-order-Id">
              <div className="Order-Details-Drawer-HeadDetails">
                <h2>Person details</h2>
              </div>
              <div className="Order-details-Drawer-Shipment-details">
                <div className="Order-details-Drawer-Product-Details">
                  <h4>
                    number of adult: <span>{orderDetails.number_of_adult}</span>
                  </h4>
                  <h4>
                    number of child: <span>{orderDetails.number_of_child}</span>
                  </h4>
                  <h4>
                    number of rooms: <span>{orderDetails.number_of_rooms}</span>
                  </h4>
                </div>
              </div>
            </div>
            <div className="Order-Details-Drawer-Details-order-Id">
              <div className="Order-Details-Drawer-HeadDetails">
                <h2>Order Summary</h2>
              </div>
              <div className="Order-details-Drawer-Shipment-details">
                <div className="Order-details-Drawer-Product-Details">
                  <div className="row">
                    <div className="col-7">
                      <div className="Order-details-Drawer-payment-details">
                        <h3>Subtotal:</h3>
                      </div>
                    </div>
                    <div className="col-5">
                      <div className="Order-details-Drawer-payment-number">
                        <h4>{orderDetails.subtotal}/- Rs</h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="Order-details-Drawer-Product-Details">
                  <div className="row">
                    <div className="col-7">
                      <div className="Order-details-Drawer-payment-details">
                        <h3>Tax:</h3>
                      </div>
                    </div>
                    <div className="col-5">
                      <div className="Order-details-Drawer-payment-number">
                        <h4>{orderDetails.tax_amount}/- Rs</h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="Order-details-Drawer-Product-Details">
                  <div className="row">
                    <div className="col-7">
                      <div className="Order-details-Drawer-payment-details">
                        <h3 className="Order-details-Drawer-OrderTotal">
                          Total Amount:
                        </h3>
                      </div>
                    </div>
                    <div className="col-5">
                      <div className="Order-details-Drawer-payment-number">
                        <h4 className="Order-details-Drawer-Orderamount">
                          {orderDetails.total_amount}/- Rs
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </Drawer>
    </>
  );
};

export default MyOrder;
