
import "../Rooms/RoomFilter.css";
import RoomCard from './RoomCard';
import React, { useEffect, useState } from 'react';
import "../Rooms/RoomCard.css";
import { FaStar } from "react-icons/fa6";
import { LuScale3D } from "react-icons/lu";
import { IoWifi } from "react-icons/io5";
import { FaRegUser } from "react-icons/fa6";
import { FaBed } from "react-icons/fa6";
import { Link } from 'react-router-dom';

const RoomFilter = () => {
    const [rooms, setRooms] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchRooms = async () => {
            try {
                const response = await fetch("https://www.skdm.in/server/royal/room.php?action=roomFetch");
                const data = await response.json();
                setRooms(data.list);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching room data:", error);
                setLoading(false);
            }
        };
        fetchRooms();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <section className='Rooms-Page-Filter-Section'>
                <div className='container'>
                    {rooms.map(room => (
                        <div key={room.room_id} className='Rooms-Page-Card-Filter-con'>
                            <>

                                <section key={room.room_id} className='Rooms-Page-Section-Card-con'>
                                    <div className='container'>

                                        <div className='Rooms-Page-Section-Card'>
                                            <div className='row'>
                                                <div className='col-md-3'>
                                                    <div className='Room-Page-Section-card-Image'>
                                                        <img src={room.images[0]?.image_view} alt={room.room_name} />
                                                    </div>
                                                </div>
                                                <div className='col-md-9'>
                                                    <div className='Room-Page-Section-Card-Details-con-main-div'>
                                                        <div className='Room-Page-Section-Card-Details-con'>
                                                            <div className='Room-Page-Card-Name-And-Reviews-Card'>
                                                                <div className='row'>
                                                                    <div className='col-md-6 col-sm-6'>
                                                                        <div className='Room-Page-card-Name-And-Star-con'>
                                                                            <h2 className="room-page-main-name">{room.room_name}</h2>
                                                                            <div className='Room-Page-card-Star-con'>
                                                                                {[...Array(5)].map((_, index) => (
                                                                                    <FaStar key={index} className={index < 4 ? "starcolor" : ""} />
                                                                                ))}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-md-6 col-sm-6'>
                                                                        <div className='Rooms-page-Card-reviews-Con'>
                                                                            <div className='Rooms-page-Card-reviews-Number'>
                                                                                <h3>Very Good</h3>
                                                                                <h4>{room.room_qty} reviews</h4>
                                                                            </div>
                                                                            <div className='Rooms-page-Card-reviews-Number-Rate'>
                                                                                <h5>4.5</h5>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className='Rooms-page-card-Details-Para'>
                                                                <p>{room.description}</p>
                                                            </div>

                                                            <div className='Rooms-page-card-RoomsDetails'>
                                                                <div className='row'>
                                                                    <div className='col-md-6 col-sm-6'>
                                                                        <div className='Rooms-page-card-RoomsDetails-Icon-And-Name-con'>
                                                                            <div className='row'>
                                                                                <div className='col-md-6'>
                                                                                    <div className='Rooms-page-card-RoomsDetails-Icon-And-Name'>
                                                                                        <IoWifi />
                                                                                        <h6>Inclusive Wifi</h6>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='col-md-6'>
                                                                                    <div className='Rooms-page-card-RoomsDetails-Icon-And-Name'>
                                                                                        <FaRegUser />
                                                                                        <h6>Adult 3</h6>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='col-md-6'>
                                                                                    <div className='Rooms-page-card-RoomsDetails-Icon-And-Name'>
                                                                                        <LuScale3D />
                                                                                        <h6>{room.room_qty} sqm</h6>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='col-md-6'>
                                                                                    <div className='Rooms-page-card-RoomsDetails-Icon-And-Name'>
                                                                                        <FaBed />
                                                                                        <h6>Queen Bed</h6>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-md-6 col-sm-6'>
                                                                        <div className='Room-Page-Card-Price-Con'>
                                                                            <div className='Room-Page-Card-Offer-Price-Btn'>
                                                                                <button>
                                                                                    Offer Price
                                                                                </button>
                                                                            </div>

                                                                        </div>
                                                                        <div className='Room-Page-Card-Offer-Price-Con'>
                                                                            <div className='Room-Page-Card-Main-Price-con'>
                                                                                <h6 className='Room-Page-Card-Main-Price'>{room.week_days} / Night</h6>
                                                                            </div>
                                                                            {/* <div className='Room-Page-Card-Offer-Price-Btn'>
                                                                                <button>
                                                                                    Offer Price
                                                                                </button>
                                                                            </div> */}
                                                                            <div className='Room-Page-Card-Offer-Price'>
                                                                                <h6> ₹{room.week_end} / Night</h6>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className='Room-Page-card-book-Btn'>
                                                                <Link to={`/RoomDetailsPage/${room.room_id}`}>
                                                                    <button>
                                                                        Book Now
                                                                    </button>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </section>

                            </>
                        </div>
                    ))}
                </div>
            </section>
        </>
    )
}

export default RoomFilter