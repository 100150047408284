import React, { useState, useLayoutEffect } from 'react';
import "../Css/Gallery.css";
import Tabs from "./Tabs";
import Items from "./Items";
import GalleryData from '../Component/GalleryData';
import Footer from '../Component/Footer/FooterSec';
import { MetaTags } from 'react-meta-tags';
import Header from "../Component/Navbar/Header";

const Gallery = () => {


    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    const [data, setData] = useState(GalleryData)

    // Store Category in CategoryData 
    const categoryData = GalleryData.map((value) => {
        return value.Category
    });
    ////////////

    const tabsData = ["all", ...new Set(categoryData)];


    // for all categoryData set 
    const filterCategory = (Category) => {
        if (Category == "all") {
            setData(GalleryData)
            return;
        }
        const filteredData = GalleryData.filter((value) => {
            return value.Category == Category;
        })

        setData(filteredData);

    }


    return (
        <>
            <MetaTags>
                <title>Family Resort in Mahabaleshwar | Royal Resort</title>
                <meta title="Family Resort in Mahabaleshwar | Royal Resort" />
                <meta name="description" content=" Discover the best family resort in Mahabaleshwar, offering comfortable accommodations, scenic views, and fun-filled activities for all ages." />
                <meta name="keywords" content="Best Resort in Mahabaleshwar, Top Resort with Rooms in Mahabaleshwar, Resort with Valley View in Mahabaleshwar, Resort with Premium Rooms in Mahabaleshwar, Best 3BHK Villa in Mahabaleshwar, Premium Villa in Mahabaleshwar, Family Resort in Mahabaleshwar, Resort near Mahabaleshwar" />
                <link rel="canonical" href="https://suryasparadise.com/family-resort-mahabaleshwar" />
                <meta http-equiv="cache-control" content="no-cache" />
                <meta http-equiv="expires" content="0" />
                <meta http-equiv="pragma" content="no-cache" />
                <meta property="og:title" content=" Family Resort in Mahabaleshwar | Royal Resort" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://suryasparadise.com/" />
                <meta property="og:description" content=" Discover the best family resort in Mahabaleshwar, offering comfortable accommodations, scenic views, and fun-filled activities for all ages." />
                <meta property="og:image" content="https://suryasparadise.com/static/media/Royal%20Resort%20Logo.ed08ad9fb16f92882bc2.png" />
            </MetaTags>

            <Header />
            {/* ///////////////////////////////// section 1 ////////////////////////////////// */}

            <section className='Gallery-Page-Section-Banner-Images'>
                <div className='Gallery-Page-Section-Banner-Images-con'>
                    <h1>Family Resort in Mahabaleshwar</h1>
                </div>
            </section>

            {/* ///////////////////////////////// section 2 ////////////////////////////////// */}

            <div className='Gallery-Section-2'>
                <div className='Gallery-Card'>

                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <Tabs filterCategory={filterCategory} tabsData={tabsData} />
                                <Items data={data} />
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <Footer />
        </>
    )
}

export default Gallery