import React, { useLayoutEffect } from 'react';
import "../Css/About.css";
import { SiGooglemaps } from "react-icons/si";
import Footer from "../Component/Footer/FooterSec";
import Room01 from "../Images/Resort/resort5.jpg";
import Room02 from "../Images/View/sunrise.jpg";
import Room03 from "../Images/Pool/Pool2.jpg";

// Team 

import Team01 from "../Images/Team/man.png";
import Team02 from "../Images/Team/woman.png";
import Team03 from "../Images/Team/profile.png";
import Team04 from "../Images/Team/man (1).png";

import Header from "../Component/Navbar/Header";
import { MetaTags } from 'react-meta-tags';
const About = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  });

  return (
    <>

      <MetaTags>
        <title>Premium Villa in Mahabaleshwar | Royal Resort</title>
        <meta title="Premium Villa in Mahabaleshwar | Royal Resort" />
        <meta name="description" content="Experience luxury at a premium villa in Mahabaleshwar, featuring elegant interiors, top-notch amenities, and breathtaking views. Ideal for a exclusive getaway." />
        <meta name="keywords" content="Best Resort in Mahabaleshwar, Top Resort with Rooms in Mahabaleshwar, Resort with Valley View in Mahabaleshwar, Resort with Premium Rooms in Mahabaleshwar, Best 3BHK Villa in Mahabaleshwar, Premium Villa in Mahabaleshwar, Family Resort in Mahabaleshwar, Resort near Mahabaleshwar" />
        <link rel="canonical" href="https://suryasparadise.com/premium-villa-mahabaleshwar" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content=" Premium Villa in Mahabaleshwar | Royal Resort" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://suryasparadise.com/" />
        <meta property="og:description" content="Experience luxury at a premium villa in Mahabaleshwar, featuring elegant interiors, top-notch amenities, and breathtaking views. Ideal for a exclusive getaway." />
        <meta property="og:image" content="https://suryasparadise.com/static/media/Royal%20Resort%20Logo.ed08ad9fb16f92882bc2.png" />
      </MetaTags>

      <Header />
      <section className='About-Page-Section-Banner-Images'>
        <div className='About-Page-Section-Banner-Images-con'>
          <h1>Premium Villa in Mahabaleshwar</h1>
        </div>
      </section>

      <section className='About-Page-Section-Details-First-con'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-5'>
              <div className='About-Page-Section-First-image-con'>
                <img src={Room01}  alt='Luxury Best Resort in Mahabaleshwar'/>
              </div>
            </div>
            <div className='col-md-7'>
              <div className='About-Page-Section-First-Details-con'>
                <h3>Luxury Best Resort in Mahabaleshwar</h3>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae delectus deserunt maxime sapiente, cupiditate mollitia eveniet ab in, qui iure iusto vel nemo maiores voluptas et dicta odit dignissimos blanditiis?
                </p>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae delectus deserunt maxime sapiente, cupiditate mollitia eveniet ab in.
                </p>
                <div className='About-Page-Section-First-Map-con'>
                  <SiGooglemaps />
                  <h4>Wnear, School, near Avakali, near Laxmi Strawberry Farm, Avakali, Mahabaleshwar, Maharashtra 412806</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='About-Page-Section-Details-Second-con'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-3'>

            </div>

            <div className='col-md-9'>
              <div className='About-Page-Section-Details-Second'>
                <div className='row'>
                  <div className='col-md-7'>
                    <div className='About-Page-Section-Second-image-con'>
                      <img src={Room02} alt='premium villa in mahabaleshwar'/>
                    </div>
                  </div>
                  <div className='col-md-5'>
                    <div className='About-Page-Section-Second-Details-Info'>
                      <div className='About-Page-Section-Second-Details-con'>
                        <h4>Resort Features</h4>
                        <ul>
                          <li>Check-in Time 3 PM</li>
                          <li>Check-out Time 12 PM</li>
                          <li>Complimentary WiFi for all members</li>
                          <li>Valet parking $20/day</li>
                          <li>Pets Welcome</li>
                          <li>Early Check- In Available</li>
                          <li>Best Prices Guranteed</li>
                          <li>Free parking for All inclusive package</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='About-Page-Section-Details-Second-con'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-9'>
              <div className='About-Page-Section-Details-Second'>
                <div className='row'>
                  <div className='col-md-5 order-md-1 order-2'>
                    <div className='About-Page-Section-Second-Details-Info'>
                      <div className='About-Page-Section-Second-Details-con'>
                        <h4>Resort Information</h4>
                        <ul>
                          <li>FIT Gym & Fitness</li>
                          <li>Wired Business Center</li>
                          <li>All Inclusive Service</li>
                          <li>Bliss & Spa</li>
                          <li>SPG Keyless</li>
                          <li>Restaurant & lounge bar</li>
                          <li>Private roomsd</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-7 order-md-2 order-1'>
                    <div className='About-Page-Section-Second-image-con'>
                      <img src={Room03} alt='best villa mahabaleshwar'/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-3'>
          </div>
        </div>
      </section>

      <section className='About-Page-Mambers-Section'>
        <div className='container'>
          <div className='About-Page-Mambers-Section-Head'>
            <h3>meet our expert members</h3>
          </div>

          <div className='About-Section-Mambers-Details-con'>
            <div className='row'>
              <div className='col-md-3'>
                <div className='About-Section-Mambers-Details-Info-con'>
                  <div className='About-Section-Mambers-Details-Name'>
                    <h3>Jone Doe</h3>
                    <h4>Front Desk Staff</h4>
                  </div>

                  <div className='About-Section-Members-Details-Image-con'>
                    <div className='About-Section-Members-Details-Image'>
                      <img src={Team01} alt='premium resort mahabaleshwar'/>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-3'>
                <div className='About-Section-Mambers-Details-Info-con'>
                  <div className='About-Section-Mambers-Details-Name'>
                    <h3>Rubby Rockson</h3>
                    <h4>Head of Security</h4>
                  </div>

                  <div className='About-Section-Members-Details-Image-con'>
                    <div className='About-Section-Members-Details-Image'>
                      <img src={Team02} alt='best resort in mahabaleshwar'/>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-3'>
                <div className='About-Section-Mambers-Details-Info-con'>
                  <div className='About-Section-Mambers-Details-Name'>
                    <h3>Milderd M. Jackson</h3>
                    <h4>Food Manager</h4>
                  </div>

                  <div className='About-Section-Members-Details-Image-con'>
                    <div className='About-Section-Members-Details-Image'>
                      <img src={Team03} alt='best resort resort in mahabaleshwar'/>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-3'>
                <div className='About-Section-Mambers-Details-Info-con'>
                  <div className='About-Section-Mambers-Details-Name'>
                    <h3>Rodney v. johnson</h3>
                    <h4>Housekeeping Supervisor</h4>
                  </div>

                  <div className='About-Section-Members-Details-Image-con'>
                    <div className='About-Section-Members-Details-Image'>
                      <img src={Team04} alt='top-resort-with-rooms-mahabaleshwar'/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}

export default About;
