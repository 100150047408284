import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
// Logo
import { IoPersonCircleOutline } from "react-icons/io5";
import { LiaUserSolid } from "react-icons/lia";
import Logo from "../../Images/Logo/Royal Resort Logo.png";
///////
import "../Navbar/Header.css";
import { Link, useNavigate } from "react-router-dom";
import { setUserToken } from "../../features/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { getProfile, getToken } from "../localstorage/localStorageServices";
import { IoIosArrowDown } from "react-icons/io";
import { Dropdown } from "react-bootstrap";
const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { access_token } = getToken();
  const { profile } = getProfile();
  const [expanded, setExpanded] = useState(false);
  const { token } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(setUserToken({ token: access_token }));
  }, [access_token, dispatch]);
  const handleNavbarToggle = () => {
    setExpanded(!expanded);
  };
  const logoutHandle = () => {
    sessionStorage.removeItem("access_token");
    sessionStorage.removeItem("profile");
    localStorage.clear();
    sessionStorage.clear();
    navigate("/");
  };
  const handleLinkClick = () => {
    setExpanded(false); // Close Navbar when a NavItem is clicked
  };

  return (
    <>
      <Navbar expand="lg" className="bg-body-tertiary" expanded={expanded}>
        <Container>
          <Navbar.Brand>
            <Link to="/">
              <div className="Logo-Con">
                <img src={Logo} alt="royal resort logo" />
              </div>
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="navbarScroll"
            onClick={handleNavbarToggle}
          />
          <Navbar.Collapse id="navbarScroll">
            <Nav className="my-2 my-lg-0" navbarScroll>
              <Nav.Link as={Link} to="/" eventKey="1" onClick={handleLinkClick}>
                Home
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/top-resort-with-rooms-mahabaleshwar"
                eventKey="2"
                onClick={handleLinkClick}
              >
                Rooms
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/premium-villa-mahabaleshwar"
                eventKey="3"
                onClick={handleLinkClick}
              >
                About us
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/family-resort-mahabaleshwar"
                eventKey="4"
                onClick={handleLinkClick}
              >
                Gallery
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/resort-near-mahabaleshwar"
                eventKey="5"
                onClick={handleLinkClick}
              >
                Contact us
              </Nav.Link>
            </Nav>
            <Nav>
              <div className="login-Navbar-main-con">
                <div className="Responsive-Navbar-Profile-con">
                  {token ? (
                    <Dropdown>
                      <Dropdown.Toggle
                        id="dropdown-basic"
                        className="navbar-dropdown"
                      >
                        <LiaUserSolid /> {profile.username}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item onClick={logoutHandle}>
                          Log Out
                        </Dropdown.Item>
                        <Dropdown.Item as={Link} to="/profile">
                          Profile
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  ) : (
                    <Nav.Link
                      href="/account/sign-in"
                      className="signinbuttionsection"
                    >
                      <Link className="nev-color" to="/account/sign-in">
                        <button className="sign-up-button">
                          <LiaUserSolid />
                          Sign In
                        </button>
                      </Link>
                    </Nav.Link>
                  )}
                </div>
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default Header;
