import React from 'react';
import "../Footer/FooterSec.css";
import Logo from "../../Images/Logo/Royal Resort Logo.png";
import { Link } from 'react-router-dom';

// Social Icons
import { IoIosCall } from "react-icons/io";
import { IoLogoWhatsapp } from "react-icons/io";
import { RiInstagramFill } from "react-icons/ri";
import { MdMail } from "react-icons/md";
import { FaFacebookSquare } from "react-icons/fa";
import { SiGooglemaps } from "react-icons/si";
import { HiMail } from "react-icons/hi";
import { FaMapMarkerAlt } from "react-icons/fa";
const FooterSec = () => {
  return (
    <>
      <section className='Footer-main-Con-Section'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-3'>
              <div className='Footer-First-Main-Con'>
                <Link to="/">
                  <div className='Footer-Logo-Image-con'>
                    <img src={Logo} alt='royal resort logo'/>
                  </div>
                </Link>
                <p>Royal Resort offers luxury, comfort, and relaxation.</p>
                <div className='Footer-Social-Con'>
                  <div className='Footer-Social-ICon'>
                    <a href="tel:+919322210409" target='blank'>
                      <IoIosCall />
                    </a>
                  </div>
                  <div className='Footer-Social-ICon'>
                    <a href="https://wa.me/+919322210409?text=" target='blank'>
                      <IoLogoWhatsapp />
                    </a>
                  </div>
                  <div className='Footer-Social-ICon'>
                    <a href='' target='blank'>
                      <RiInstagramFill />
                    </a>
                  </div>
                  <div className='Footer-Social-ICon'>
                    <a href="mailto: suryasparadise@gmail.com" target='blank'>
                      <MdMail />
                    </a>
                  </div>
                  <div className='Footer-Social-ICon'>
                    <a href='' target='blank'>
                      <FaFacebookSquare />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-2'>
              <div className='Footer-Second-Main-Con'>
                <div className='Footer-Second-Head-con'>
                  <h3>Useful Link</h3>
                </div>
                <div className='Footer-Second-Pahe-Link-con'>
                  <ul>
                    <li>
                      <Link to="/">
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link to="/top-resort-with-rooms-mahabaleshwar">
                        Rooms
                      </Link>
                    </li>
                    <li>
                      <Link to='/premium-villa-mahabaleshwar' >
                        About
                      </Link>
                    </li>
                    <li>
                      <Link to="/family-resort-mahabaleshwar">
                        Gallery
                      </Link>
                    </li>
                    <li>
                      <Link to="/resort-near-mahabaleshwar">
                        Contact
                      </Link>
                    </li>
                    <li>
                      <Link to="/policy">
                      Hotel Policy & Booking Conditions
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className='col-md-3'>
              <div className='Footer-Map-Con'>
              <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3796.128661459912!2d73.7099381!3d17.926152!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc27b8ffcc60ea7%3A0xa8592acc789d2312!2sRoyal%20Resort%20Mahabaleshwar!5e0!3m2!1sen!2sin!4v1713512442438!5m2!1sen!2sin" width="100%" height="100%" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
              </div>
            </div>
            <div className='col-md-4'>
              <div className='Footer-Second-Main-Con'>
                <div className='Footer-Second-Head-con'>
                  <h3>Contact Us</h3>
                </div>
                <div className='Footer-Second-Pahe-Link-con'>
                  <div className='Footer-Contact-Details-Icon-con'>
                    <FaMapMarkerAlt />
                    <div className='Footer-Contact-Details-con'>
                      <a href='https://maps.app.goo.gl/Ex4SfsHeuQWkSBiN9' target='blank'>
                        <p>near, School, near Avakali, near Laxmi Strawberry Farm, Avakali, Mahabaleshwar, Maharashtra 412806</p>
                      </a>
                    </div>
                  </div>

                  <div className='Footer-Contact-Details-Icon-con'>
                    <HiMail />
                    <div className='Footer-Contact-Details-con'>
                      <a href="mailto: suryasparadise@gmail.com" target='blank'>
                        <p>suryasparadise@gmail.com</p>
                      </a>
                    </div>
                  </div>

                  <div className='Footer-Contact-Details-Icon-con'>
                    <IoIosCall />
                    <div className='Footer-Contact-Details-con'>
                      <a href="tel:+919322210409" target='blank'>
                        <p>+91 93222 10409</p>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default FooterSec