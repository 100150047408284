import React from 'react';
import "../About/HomeAbout.css";

import about1 from "../../Images/Resort/resort11.jpg";
import about2 from "../../Images/View/sunrise.jpg";
import { Link } from 'react-router-dom';

const HomeAbout = () => {
  return (
    <>
      <section className="offer-area home-two">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div
                className="section-title home-two"
                data-cue="zoomIn"
                data-show="true"
                style={{
                  animationName: "zoomIn",
                  animationDuration: "2500ms",
                  animationTimingFunction: "ease",
                  animationDelay: "0ms",
                  animationDirection: "normal",
                  animationFillMode: "both"
                }}
              >
                <h4>Exclusive Agency</h4>
                <h1>A Welcoming Haven for All Kind of<br />Travelers and Nature Lovers</h1>

                <p className="section-desc home-two">
                Welcome to Royal Resort, the best resort in Mahabaleshwar, where your dream getaway becomes a reality! Situated in the heart of the stunning Mahabaleshwar hills, our resort offers an idyllic escape with breathtaking views, lush greenery, and cool mountain breezes. Whether you’re seeking a peaceful retreat or an adventurous vacation, Royal Resort promises to provide an unforgettable experience for all.
                </p>
              </div>
              <div
                className="offer-thumb home-two Image-con"
                data-cue="zoomIn"
                data-show="true"
                style={{
                  animationName: "zoomIn",
                  animationDuration: "2500ms",
                  animationTimingFunction: "ease",
                  animationDelay: "0ms",
                  animationDirection: "normal",
                  animationFillMode: "both"
                }}
              >
                <img src={about1} alt="Best Resort in Mahabaleshwar" />
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="offer-thumb home-two upper Image-con"
                data-cue="zoomIn"
                data-show="true"
                style={{
                  animationName: "zoomIn",
                  animationDuration: "2500ms",
                  animationTimingFunction: "ease",
                  animationDelay: "0ms",
                  animationDirection: "normal",
                  animationFillMode: "both"
                }}
              >
                <img src={about2} alt="Best Resort Mahabaleshwar" />
              </div>
              <div
                className="section-title home-two"
                data-cue="zoomIn"
                data-show="true"
                style={{
                  animationName: "zoomIn",
                  animationDuration: "2500ms",
                  animationTimingFunction: "ease",
                  animationDelay: "0ms",
                  animationDirection: "normal",
                  animationFillMode: "both"
                }}
              >
                <p className="section-desc home-two">
                We have thoughtfully designed our resort to offer the perfect balance of luxury, comfort, and nature. With spacious rooms and modern amenities, every corner of Royal Resort has been crafted to make you feel right at home. Each room is designed to offer panoramic views of the lush hills and valleys, ensuring you wake up to the serene beauty of Mahabaleshwar every day. We strive to offer a blend of relaxation and excitement with a range of recreational activities, including nature walks, outdoor games, and more.
                </p>
              </div>
              <div className="luxury-button home-two" data-cue="zoomIn">
                <Link to="/premium-villa-mahabaleshwar">See More Tour</Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default HomeAbout