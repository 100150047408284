import Pool01 from "../Images/Gallery/Pool/9T0A0289.JPG";
import Pool02 from "../Images/Gallery/Pool/9T0A0289.JPG";
// Foods
import Foods01 from "../Images/Gallery/Foods/9T0A0059.JPG";
import Foods02 from "../Images/Gallery/Foods/9T0A0066.JPG";
// Resort Interior
import ResortInterior01 from "../Images/Gallery/Interior/9T0A0166.JPG";
import ResortInterior23 from "../Images/Gallery/Interior/9T0A0462.JPG";

const GalleryData = [
  {
    id: 1,
    Category:"Pool",
    // CategoryName:"Pool",
    CImage:Pool01,
  },
  {
    id: 1,
    Category:"Pool",
    // CategoryName:"Pool",
    CImage:Pool02,
  },
 
  
  {
    id: 1,
    Category:"Foods",
    // CategoryName:"Pool",
    CImage:Foods01,
  },
  {
    id: 1,
    Category:"Foods",
    // CategoryName:"Pool",
    CImage:Foods02,
  },
  
 
 
  {
    id: 1,
    Category:"Resort Interior",
    // CategoryName:"Pool",
    CImage:ResortInterior01,
  },
 
  {
    id: 1,
    Category:"Resort Interior",
    // CategoryName:"Pool",
    CImage:ResortInterior23,
  },

];

export default GalleryData;

