import React from 'react';
import "../Counter/CounterSec.css";


const CounterSec = () => {
    return (
        <>
            <section className='Home-Section-Counter-con'>
                <div className='conatiner'>
                    <div className='Home-Section-Counter-Head'>
                        <h2>Number Speaks</h2>
                        <div className='Home-Section-Counter-Head-Para'>
                            <p>The Perfect Place To Stay</p>
                        </div>
                    </div>

                    <div className='Home-Section-Counter-Details-con'>
                        <div className='Home-Section-Counter-Details'>
                            <div className='row'>
                                <div className='col-md-3 col-sm-6'>
                                    <div className='Home-Section-Counter-Details-info'>
                                        <h3>14</h3>
                                        <h4>Cottages</h4>
                                    </div>
                                </div>
                                <div className='col-md-3 col-sm-6'>
                                    <div className='Home-Section-Counter-Details-info'>
                                        <h3>80</h3>
                                        <h4>Acres of Green Space</h4>
                                    </div>
                                </div>
                                <div className='col-md-3 col-sm-6'>
                                    <div className='Home-Section-Counter-Details-info'>
                                        <h3>35</h3>
                                        <h4>Bedrooms</h4>
                                    </div>
                                </div>
                                <div className='col-md-3 col-sm-6'>
                                    <div className='Home-Section-Counter-Details-info'>
                                        <h3>97 <span>%</span></h3>
                                        <h4>Satisfied Customer</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default CounterSec