import React from 'react';
import "../Activities/ActivitiesSec.css";
import SwimmingPool from "../../Images/Activites/swim.png";
import royal from "../../Images/Aayush Images/royal.png";

import  chess from "../../Images/Activites/chess.png";
import carromboard from "../../Images/Activites/carrom-board-game.png";
import football from "../../Images/Activites/football.png";
import cricket from "../../Images/Activites/cricket.png";
import badminton from "../../Images/Activites/badminton.png";

const ActivitiesSec = () => {
    return (
        <>
            <section className='Home-activities-section-2'>
                <div className='Home-activities-Section-Head'>
                    <h2>
                        Activities
                        <div className='HeadImageoverlay'>
                            <img src={royal} alt='royal resort' />
                        </div>
                    </h2>
                </div>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6 col-md-12'>
                            <div className='Home-Section-activities-details-2'>
                                <div className='Home-Section-activities-2'>
                                    <div className='row'>
                                        <div className='col-md-4 col-6'>
                                            <div className='Home-Section-activities-icons-body'>
                                                <div className='Home-Section-Activities-Image-con'>
                                                    <div className='Home-Section-Activities-Image'>
                                                        <img src={chess} alt='chess activities resort in mahabaleshwar'/>
                                                    </div>
                                                </div>
                                                <div className='Home-Section-Activities-Image-Name'>
                                                    <h3>Chess</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-4 col-6'>
                                            <div className='Home-Section-activities-icons-body'>
                                                <div className='Home-Section-Activities-Image-con'>
                                                    <div className='Home-Section-Activities-Image'>
                                                        <img src={carromboard} alt='carromboard activities resort in mahabaleshwar'/>
                                                    </div>
                                                </div>
                                                <div className='Home-Section-Activities-Image-Name'>
                                                    <h3>Carrom Board</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-4 col-6'>
                                            <div className='Home-Section-activities-icons-body'>
                                                <div className='Home-Section-Activities-Image-con'>
                                                    <div className='Home-Section-Activities-Image'>
                                                        <img src={SwimmingPool} alt='SwimmingPool activities resort in mahabaleshwar'/>
                                                    </div>
                                                </div>
                                                <div className='Home-Section-Activities-Image-Name'>
                                                    <h3>Swimming Pool</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-4 col-6'>
                                            <div className='Home-Section-activities-icons-body'>
                                                <div className='Home-Section-Activities-Image-con'>
                                                    <div className='Home-Section-Activities-Image'>
                                                        <img src={football} alt='football activities resort in mahabaleshwar'/>
                                                    </div>
                                                </div>
                                                <div className='Home-Section-Activities-Image-Name'>
                                                    <h3>Football</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-4 col-6'>
                                            <div className='Home-Section-activities-icons-body'>
                                                <div className='Home-Section-Activities-Image-con'>
                                                    <div className='Home-Section-Activities-Image'>
                                                        <img src={cricket} alt='cricket activities resort in mahabaleshwar'/>
                                                    </div>
                                                </div>
                                                <div className='Home-Section-Activities-Image-Name'>
                                                    <h3>Cricket</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-4 col-6'>
                                            <div className='Home-Section-activities-icons-body'>
                                                <div className='Home-Section-Activities-Image-con'>
                                                    <div className='Home-Section-Activities-Image'>
                                                        <img src={badminton} alt='badminton activities resort in mahabaleshwar'/>
                                                    </div>
                                                </div>
                                                <div className='Home-Section-Activities-Image-Name'>
                                                    <h3>Badminton</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-7 col-md-12'>
                        </div>
                    </div>

                </div>

            </section>
        </>
    )
}

export default ActivitiesSec;