import React, { useLayoutEffect, useRef, useState } from 'react';
// import 'animate.css';
import "../Css/Home.css";
import SearchBar from '../Component/Searchbar/SearchBar';
import HomeAbout from '../Component/About/HomeAbout';
import HomeRooms from '../Component/HomeRoom/HomeRooms';
import ActivitiesSec from '../Component/Activities/ActivitiesSec';
import AmenitiesSec from '../Component/Amenities/AmenitiesSec';
import Packages from '../Component/Our Packages/Packages';
import CounterSec from '../Component/Counter/CounterSec';
import Attraction from '../Component/Nearby/Attraction';
import BlogSec from '../Component/Blog/BlogSec';
import FooterSec from '../Component/Footer/FooterSec';
import { Link } from 'react-router-dom';
import Header from '../Component/Navbar/Header';
import MetaTags from "react-meta-tags"
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import './styles.css';

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

import demo1 from "../Images/Rooms/ABP_8588-Pano.jpg";
import bannerImage01 from "../Images/Resort/resort11.jpg";
// import bannerImage01 from "../Images/Aayush Images/ABP_9244.jpg";
import bannerImage02 from "../Images/View/sunrise.jpg";
import bannerImage03 from "../Images/Pool/pool10.jpg";
import bannerImage05 from "../Images/Aayush Wedding/Wedding Aayush Resort.jpg";


const Home = () => {

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    return (
        <>

            <MetaTags>
                <title>Best Resort in Mahabaleshwar | Royal Resort</title>
                <meta title="Best Resort in Mahabaleshwar | Royal Resort" />
                <meta name="description" content="Discover the ultimate getaway at the best resort in Mahabaleshwar. Enjoy breathtaking views, world-class amenities, & matched hospitality for a best vacation." />
                <meta name="keywords" content="Best Resort in Mahabaleshwar, Top Resort with Rooms in Mahabaleshwar, Resort with Valley View in Mahabaleshwar, Resort with Premium Rooms in Mahabaleshwar, Best 3BHK Villa in Mahabaleshwar, Premium Villa in Mahabaleshwar, Family Resort in Mahabaleshwar, Resort near Mahabaleshwar" />
                <link rel="canonical" href="https://suryasparadise.com/" />
                <meta http-equiv="cache-control" content="no-cache" />
                <meta http-equiv="expires" content="0" />
                <meta http-equiv="pragma" content="no-cache" />
                <meta property="og:title" content=" Best Resort in Mahabaleshwar | Royal Resort" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://suryasparadise.com/" />
                <meta property="og:description" content="Discover the ultimate getaway at the best resort in Mahabaleshwar. Enjoy breathtaking views, world-class amenities, & matched hospitality for a best vacation." />
                <meta property="og:image" content="https://suryasparadise.com/static/media/Royal%20Resort%20Logo.ed08ad9fb16f92882bc2.png" />
            </MetaTags>

            <section className='Section-Home-banner'>
                <Header />
                <div className='demo'>
                    <Swiper
                        spaceBetween={30}
                        centeredSlides={true}
                        loop={true}
                        autoplay={{
                            delay: 3500,
                            disableOnInteraction: false,
                        }}
                        pagination={{
                            clickable: true,
                        }}
                        navigation={true}
                        modules={[Pagination,Autoplay, Navigation]}
                        className="mySwiper"
                    >
                        <SwiperSlide>
                            <div className='Home-Section-banner-con' style={{ backgroundImage: ` linear-gradient(90deg, rgba(0, 0, 0, .3), rgba(0, 0, 0, .4)),url(${bannerImage01})`, backgroundAttachment: 'fixed' }}>
                                <div className='Home-Section-Banner-text'>
                                    <h1 className='animate__animated animate__backInDown'>Best Resort <br></br> in Mahabaleshwar</h1>
                                    <p className='animate__animated animate__zoomIn'>Discover the best <br></br> luxury rooms</p>
                                    <div className='Login-Btn banner-btn animate__animated animate__bounce'>
                                        <Link to="/top-resort-with-rooms-mahabaleshwar">
                                            <button>
                                                Book Now
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='Home-Section-banner-con' style={{ backgroundImage: ` linear-gradient(90deg, rgba(0, 0, 0, .3), rgba(0, 0, 0, .4)),url(${bannerImage02})`, backgroundAttachment: 'fixed' }}>
                                <div className='Home-Section-Banner-text'>
                                    <h2 className='animate__animated animate__backInDown'>Welcome To <br></br> Royal Resort</h2>
                                    <p className='animate__animated animate__zoomIn'>Discover the best <br></br> luxury rooms</p>
                                    <div className='Login-Btn banner-btn animate__animated animate__bounce'>
                                        <Link to="/top-resort-with-rooms-mahabaleshwar">
                                            <button>
                                                Book Now
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='Home-Section-banner-con' style={{ backgroundImage: ` linear-gradient(90deg, rgba(0, 0, 0, .3), rgba(0, 0, 0, .4)),url(${bannerImage03})`, backgroundAttachment: 'fixed' }}>
                                <div className='Home-Section-Banner-text'>
                                    <h2 className='animate__animated animate__backInDown'>Welcome To <br></br> Royal Resort</h2>
                                    <p className='animate__animated animate__zoomIn'>Discover the best <br></br> luxury rooms</p>
                                    <div className='Login-Btn banner-btn animate__animated animate__bounce'>
                                        <Link to="/top-resort-with-rooms-mahabaleshwar">
                                            <button>
                                                Book Now
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </section>
            {/* <SearchBar /> */}
            <HomeAbout />
            <Packages />
            {/* <HomeRooms /> */}
            <ActivitiesSec />
            <AmenitiesSec />
            <CounterSec />
            <Attraction />
            {/* <BlogSec /> */}

            <FooterSec />
        </>
    )
}

export default Home