import React, { useLayoutEffect } from 'react';
import "../Css/Rooms.css";
import "../Component/Navbar/Header";
import BannerBar from '../Component/SearchbarSecond/BannerBar';
import RoomFilter from '../Component/Rooms/RoomFilter';
import Footer from "../Component/Footer/FooterSec";
import Header from "../Component/Navbar/Header";
import { MetaTags } from 'react-meta-tags';

const Rooms = () => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    return (
        <>

            <MetaTags>
                <title>Top Resort with Rooms in Mahabaleshwar | Royal Resort</title>
                <meta title="Top Resort with Rooms in Mahabaleshwar | Royal Resort" />
                <meta name="description" content="Stay at the top resort in Mahabaleshwar, offering luxurious rooms, serene surroundings, and premium amenities. Perfect for a relaxing. Book now!." />
                <meta name="keywords" content="Best Resort in Mahabaleshwar, Top Resort with Rooms in Mahabaleshwar, Resort with Valley View in Mahabaleshwar, Resort with Premium Rooms in Mahabaleshwar, Best 3BHK Villa in Mahabaleshwar, Premium Villa in Mahabaleshwar, Family Resort in Mahabaleshwar, Resort near Mahabaleshwar" />
                <link rel="canonical" href="https://suryasparadise.com/resort-valley-view-mahabaleshwar" />
                <meta http-equiv="cache-control" content="no-cache" />
                <meta http-equiv="expires" content="0" />
                <meta http-equiv="pragma" content="no-cache" />
                <meta property="og:title" content=" Top Resort with Rooms in Mahabaleshwar | Royal Resort" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://suryasparadise.com/" />
                <meta property="og:description" content="Stay at the top resort in Mahabaleshwar, offering luxurious rooms, serene surroundings, and premium amenities. Perfect for a relaxing. Book now!" />
                <meta property="og:image" content="https://suryasparadise.com/static/media/Royal%20Resort%20Logo.ed08ad9fb16f92882bc2.png" />
            </MetaTags>

            <Header />
            <section className='Rooms-Section-Banner-List-Images'>
                <div className='Rooms-Section-Banner-Images-con'>
                    <h1>Top Resort with Rooms in Mahabaleshwar</h1>
                </div>
            </section>
            {/* <BannerBar /> */}
            <RoomFilter />
            <Footer />
        </>
    )
}

export default Rooms