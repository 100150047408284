import React, { useEffect, useState } from "react";
import "../RoomDetails/RoomDetailsPage.css";
import { FaWifi } from "react-icons/fa6";
import { FaStar } from "react-icons/fa6";
import { TbAirConditioning } from "react-icons/tb";
import { FaPersonSwimming } from "react-icons/fa6";
import { RiFridgeLine } from "react-icons/ri";
import { BsFillSafe2Fill } from "react-icons/bs";
import { PiTelevisionSimpleBold } from "react-icons/pi";
import Footer from "../../Component/Footer/FooterSec";
import Header from "../Navbar/Header";

import { Link, useParams, useNavigate } from "react-router-dom";
import Room from "../../Images/Room/Room1.jpg";
import Room01 from "../../Images/Room/dining area 2.jpg";
import Room02 from "../../Images/Room/hall1.jpg";
import Room03 from "../../Images/Room/resort balcony.jpeg";
import Room04 from "../../Images/Room/room9.JPG";

import { DatePicker, Progress } from "antd";
import moment from "moment/moment";
import { useBooking } from "../../context/BookingProvider";
const RoomDetailsPage = () => {
  const { room_id } = useParams(); // Get room_id from the URL
  const [roomDetails, setRoomDetails] = useState(null);
  const navigate = useNavigate(); // Initialize useNavigate
  const [availableRooms, setAvailableRooms] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const {
    checkInDate,
    setCheckInDate,
    checkOutDate,
    setCheckOutDate,
    adultQuantity,
    setAdultQuantity,
    childrenQuantity,
    setChildrenQuantity,
    roomsQuantity,
    setRoomsQuantity,
  } = useBooking();

  console.log(adultQuantity);
  console.log(roomsQuantity);



  const handleAdultIncrement = () => {
    setAdultQuantity(prevQuantity => prevQuantity + 1);
  };

  const handleAdultDecrement = () => {
    if (adultQuantity > 1) {
      setAdultQuantity(prevQuantity => prevQuantity - 1);
    }
  };
  const handleRoomsIncrement = () => {
    setRoomsQuantity(prevQuantity => prevQuantity + 1);
  };

  const handleRoomsDecrement = () => {
    if (roomsQuantity > 1) {
      setRoomsQuantity(prevQuantity => prevQuantity - 1);
    }
  };

  useEffect(() => {
    fetchRoomDetails();
  }, [room_id]);

  const fetchRoomDetails = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://www.skdm.in/server/royal/room.php?action=roomFetch&room_id=${room_id}`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch room details");
      }
      const data = await response.json();
      setRoomDetails(data);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };



  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const handleBooking = async () => {
    if (!checkInDate || !checkOutDate) {
      alert("Please select both Check In and Check Out dates.");
      return;
    }
  
    const formData = new FormData();
    formData.append("action", "checkroomavailability");
    formData.append("start_date", formatDate(checkInDate));
    formData.append("end_date", formatDate(checkOutDate));
    formData.append("room_id", room_id); // Include room_id in the request
  
    try {
      setLoading(true);
      const response = await fetch(
        "https://www.skdm.in/server/royal/available_check.php",
        {
          method: "POST",
          body: formData,
        }
      );
  
      if (!response.ok) {
        throw new Error("Failed to check room availability");
      }
  
      const data = await response.json();
      setAvailableRooms(data);
  
      if (data.room) {
        navigate(`/booking/${room_id}`); // Navigate to booking if available
        alert("Room is available! Proceed with booking.");
      } else {
        alert("Sorry, no rooms available for the selected dates.");
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const disabledCheckInDate = (current) =>
    current && (current < moment().startOf("day") || (checkOutDate && current > checkOutDate));

  const disabledCheckOutDate = (current) =>
    current && (current < moment().startOf("day") || (checkInDate && current <= checkInDate));

  const handleCheckInChange = (date) => {
    setCheckInDate(date);
    if (moment(date).isAfter(checkOutDate)) {
      setCheckOutDate(null);
    }
  };

  const handleCheckOutChange = (date) => setCheckOutDate(date);

  if (loading) return <p>Loading room details...</p>;
  if (error) return <p>Error: {error}</p>;
  if (!roomDetails) return <p>No room details found</p>;

  const { room_name, description, week_days, week_end, images } = roomDetails;
  const primaryImage = images.slice(0, 1)[0]; // First image
  const restImages = images.slice(1); // Remaining images 

  return (
    <>
      <Header />
      <section className="Rooms-Section-Banner-Images">
        <div className="Rooms-Section-Banner-Images-con">
          <h1>{room_name}</h1>
        </div>
      </section>
      {/* <BannerBar /> */}
      <div className="container">
        <div className="top-bottom">
          <div className="Room-Details-Head-Main-con">
            <h2>{room_name}</h2>
            {/* <p style={{ color: "orange" }}>
              <FaStar /> <FaStar /> <FaStar /> <FaStar />
              <FaStar />
            </p> */}
          </div>
          <div className="Room-Details-Head-Details-con">
            {/* <p className="Room-Details-Page-Card-Main-Price">$300/Night</p> */}
            <h4> ₹ {week_days} / Weekday - ₹ {week_end} / Weekend</h4>
          </div>
        </div>
        <div className="imagesectionn">

          <div className="row">

            <div className="col-6">
              {primaryImage && (
                <div className="Image-Section-Room-Details-con">
                  <img className="image-frst" src={primaryImage.image_view}
                    alt={primaryImage.image_name} />
                </div>
              )}

            </div>

            <div className="col-6">
              <div className="row">
                {restImages.map((img) => (
                  <div key={img.image_id} className="col-6">
                    <div className="Image-Section-Room-Details-con-Second">
                      <img className="image-frst" src={img.image_view} alt={img.image_name} />
                    </div>
                  </div>


                ))}
              </div>
            </div>
          </div>

        </div>
        <div className="top-bottom">
          <div className="row">
            <div className="col-6">
              <div className="Room-Details-Page-Description">
                <h3>Description</h3>
                <p>{description || "No description available"}</p>
              </div>
            </div>
            <div className="col-6">
              <div className="Room-Details-Page-Description">
                <h3>Room Aminity</h3>
                <div className="row">
                  <div className="col-6">
                    <div className="ul styleul">
                      <li className="iconsection">
                        <span className="serviceicon">
                          <TbAirConditioning />
                        </span>
                        A/C
                      </li>
                      <li className="iconsection">
                        <span className="serviceicon">
                          <FaPersonSwimming />
                        </span>
                        Swiming Pool
                      </li>
                      <li className="iconsection">
                        <span className="serviceicon">
                          <RiFridgeLine />
                        </span>
                        Fridge
                      </li>
                      <li className="iconsection">
                        <span className="serviceicon">
                          <BsFillSafe2Fill />
                        </span>
                        Safe Box
                      </li>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="ul styleul">
                      <li className="iconsection">
                        <span className="serviceicon">
                          <FaWifi />
                        </span>
                        Wifi
                      </li>
                      <li className="iconsection">
                        <span className="serviceicon">
                          <PiTelevisionSimpleBold />
                        </span>
                        T.v
                      </li>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="top-bottom">
          <div className="row">

            <div className="col-6">
              <div className="Room-Details-Facility-head">
                <h3>Our Facility</h3>
              </div>
              <div className="row">
                <div className="col-6">
                  <ul className="liststyle-review">
                    <li>Kettle, tea & coffee</li>
                    <li>Comfortable beds</li>
                    <li>Free Wi-fi</li>
                    <li>Air conditione</li>
                    <li>Breakfast included</li>
                    <li>Wardrobe</li>
                  </ul>
                </div>
                <div className="col-6">
                  <ul className="liststyle-review">
                    <li>Bathrobe & slippers</li>
                    <li>Balcony or terrace</li>
                    <li>Safe</li>
                    <li>Shower bathtub</li>
                    <li>Working table</li>
                    <li>Shampoo and soap</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="Room-Details-Form">
                <form>
                  <div className="Form-Date-Piker">
                    <div className="form-group">
                      <label htmlFor="fullName">Check In</label>
                      <DatePicker
                        format="DD MMM YYYY"
                        onChange={handleCheckInChange}
                        value={checkInDate}
                        disabledDate={disabledCheckInDate}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="fullName">Check Out</label>
                      <DatePicker
                        format="DD MMM YYYY"
                        onChange={handleCheckOutChange}
                        value={checkOutDate}
                        disabledDate={disabledCheckOutDate}
                      />
                    </div>
                  </div>

                  <div className="FormData-Person-Select">
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-4 col-xs-6">
                        <div className="inputfiled">
                          <h6 className="leftclassname">Adults</h6>
                          <div className="containerer">
                            <div className="quantity">
                              <div
                                className="quantity__minus"
                                onClick={handleAdultDecrement}
                              >
                                <span>-</span>
                              </div>
                              <input
                                name="adultQuantity"
                                type="text"
                                className="quantity__input"
                                value={adultQuantity}
                                readOnly
                              />
                              <div
                                className="quantity__plus"
                                onClick={handleAdultIncrement}
                              >
                                <span>+</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-6 col-sm-4 col-xs-6">
                        <div className="inputfiled">
                          <h6 className="leftclassname">Rooms</h6>
                          <div className="containerer">
                            <div className="quantity">
                              <div
                                className="quantity__minus"
                                onClick={handleRoomsDecrement}
                              >
                                <span>-</span>
                              </div>
                              <input
                                name="roomsQuantity"
                                type="text"
                                className="quantity__input"
                                value={roomsQuantity}
                                readOnly
                              />
                              <div
                                className="quantity__plus"
                                onClick={handleRoomsIncrement}
                              >
                                <span>+</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="CheckAvailability">
                  <button
  type="button"
  onClick={handleBooking}
  disabled={!checkInDate || !checkOutDate}
>
  Check Availability
</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="row">
            <div className="col-12">
              <div className="Room-Details-Page-Description">
                <h3>Review</h3>
                <p>
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit. Vel
                  totam similique ipsum.
                </p>
              </div>
            </div>
          </div>
        </div>

      </div>

      <Footer />
    </>
  );
};

export default RoomDetailsPage;


