import React, { useEffect, useState } from "react";
import "../BookingReview/ReviewBooking.css";
import { FaStar } from "react-icons/fa6";
import { Button, message } from "antd";
import FooterSec from "../Footer/FooterSec";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { useBooking } from "../../context/BookingProvider";
import moment from "moment";
import { useSelector } from "react-redux";
import Header from "../Navbar/Header";

const ReviewBooking = () => {
  const { checkInDate, checkOutDate, adultQuantity, roomsQuantity } = useBooking();
  const [numberOfNights, setNumberOfNights] = useState(0);
  const { room_id } = useParams();
  const [loading, setLoading] = useState(false);
  const [roomDetails, setRoomDetails] = useState(null);
  const [userdata, setuserdata] = useState([]);
  const { token } = useSelector((state) => state.auth);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (room_id) {
      fetchRoomDetails();
    }
    fetchuserid(); // Call this function unconditionally
  }, [room_id, token]); // Add token as a dependency

  const fetchRoomDetails = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://www.skdm.in/server/royal/room.php?action=roomFetch&room_id=${room_id}`
      );
      if (!response.ok) throw new Error("Failed to fetch room details");
      const data = await response.json();
      setRoomDetails(data);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchuserid = async () => {
    if (!token) return; // Early return if token is not available
    try {
      const response = await fetch(
        `https://www.skdm.in/server/royal/user.php?action=fetch&token=${token}`
      );

      if (!response.ok) {
        throw new Error("Failed to fetch user data");
      }
      const data = await response.json();
      setuserdata(data.user); // Set fetched room data to state
    } catch (error) {
      console.error("Error fetching room data:", error);
    }
  };

  const username = userdata.user_name;
  const user_Email = userdata.user_email;
  const user_mobile = userdata.user_mobile;
  const user_id = userdata.user_id;

  useEffect(() => {
    calculateNumberOfNights(checkInDate, checkOutDate);
  }, [checkInDate, checkOutDate]);

  const getDaysDifference = (date1, date2) => {
    const difference_ms = Math.abs(date2.getTime() - date1.getTime());
    return Math.floor(difference_ms / (1000 * 60 * 60 * 24));
  };

  const calculateNumberOfNights = (checkInDate, checkOutDate) => {
    if (checkInDate && checkOutDate) {
      const startDate = new Date(checkInDate);
      const endDate = new Date(checkOutDate);
      const differenceInDays = getDaysDifference(startDate, endDate);
      setNumberOfNights(differenceInDays);
    }
  };

console.log("calculateNumberOfNights",calculateNumberOfNights)

  const calculateTotalAmount = () => {
    const { week_days = 0 } = roomDetails || {};
    console.log("week_days",week_days)
    const basePrice = numberOfNights * week_days * roomsQuantity;
    console.log("basePrice",basePrice)
    const gst = (basePrice * 18) / 100; // Calculate 18% GST
    const totalAmount = basePrice + gst; // Total amount includes GST
    return { totalAmount: totalAmount.toFixed(2), gst: gst.toFixed(2) };
  };

  const encodeData = (data) => {
    const jsonString = JSON.stringify(data); // Convert array to JSON string
    return btoa(jsonString); // Encode JSON string to Base64
  };

  const handlePlaceOrder = async (e) => {
    e.preventDefault();
  
    // Check if user_id exists, otherwise show an error and return early
    if (!user_id) {
      message.error("Please login first to place your order.");
      return;
    }
  
    setLoading(true);
  
    const { totalAmount } = calculateTotalAmount();
  
    const data = {
      user_id: user_id,
      room_id: room_id,
      check_in: moment(new Date(checkInDate)).format("YYYY-MM-DD"),
      check_out: moment(new Date(checkOutDate)).format("YYYY-MM-DD"),
      order_status: "decline",
      room_id: room_id,
      order_amount: totalAmount,
      room_qty: roomsQuantity,
    };
  
    console.log("data", encodeData(data));
  
    const bookingData = new FormData();
    bookingData.append("action", "add");
    bookingData.append("detail", encodeData(data));
  
    try {
      // Step 1: Send booking data
      const bookingResponse = await axios.post(
        "https://www.skdm.in/server/royal/booking.php",
        bookingData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );
  
      // Check if the booking was successful
      if (bookingResponse.data.status === "Order created successfully") {
        const { order_id } = bookingResponse.data; // Use the order_id from the response
        const paymentData = {
          client_id: "yourClientId",
          customer_email: userdata.user_email,
          customer_phone: userdata.user_mobile,
          first_name: userdata.user_name,
          check_in: checkInDate,
          check_out: checkOutDate,
          room_id: room_id,
          qty: roomsQuantity,
          user_id: user_id,
          amount: totalAmount,
          order_id: order_id,
        };
  
        const paymentResponse = await axios.post(
          "https://www.skdm.in/server/royal/pay.php",
          paymentData,
          { headers: { "Content-Type": "application/json" } }
        );
  
        message.success("Redirecting to payment page...");
        window.location.href = paymentResponse.data.payment_url;
      } else {
        throw new Error("Booking failed. Please try again.");
      }
    } catch (error) {
      console.error("Booking or payment initiation failed:", error.response?.data || error);
      message.error("Booking or payment failed. Please try again.");
    } finally {
      setLoading(false);
    }
  };
  

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  const { room_name, description, week_days } = roomDetails || {};
  const { totalAmount, gst } = calculateTotalAmount();

  return (
    <>
    <Header/>
      <section className="Review-Booking-Section-Con">
        <div className="container">
          <div className="Review-Booking-Section-Head">
            <h2>Review Your Booking</h2>
          </div>
          <form onSubmit={handlePlaceOrder}>
            <div className="Review-Booking-Section-Details-con">
              <div className="row">
                <div className="col-md-8">
                  <div className="Review-Booking-Section-Details-Info-Main-con">
                    <div className="Review-Booking-Section-Details-Name-Star">
                      <h3 style={{ textAlign: "left" }}>
                        {room_name || "Room Name Not Available"}
                      </h3>
                      <div className="Review-Booking-Section-Details-Star-con">
                        {[...Array(5)].map((_, index) => (
                          <FaStar key={index} className="starcolor" />
                        ))}
                      </div>
                    </div>

                    <div className="Review-Booking-Section-Day-People-Details-con">
                      <div className="Review-Booking-Section-Day-Details-con">
                        <div className="Review-Booking-Section-Check-Details">
                          <h4>Check-In</h4>
                          <h5>{moment(new Date(checkInDate)).format("DD-MM-YYYY")}</h5>
                        </div>
                        <div className="Review-Booking-Section-Check-Details Review-Booking-Section-Check-Details-margin">
                          <h4>Check-Out</h4>
                          <h5>{moment(new Date(checkOutDate)).format("DD-MM-YYYY")}</h5>
                        </div>
                      </div>
                      <div className="Review-Booking-Section-People-Details-con">
                        <h6>{adultQuantity} Person(s)</h6>
                        <h6>{roomsQuantity} Room(s)</h6>
                        <h6>{numberOfNights} Night(s)</h6>
                      </div>
                    </div>

                    <div className="Review-Booking-Section-Instruction-con">
                      <ul>
                        <li>{description || "Room description unavailable"}</li>
                        <li>No meals included</li>
                        <li>Free welcome drink</li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="Review-Price-Section-Details-con">
                    <h2>Price Breakout</h2>
                    <div className="Review-Price-Section-Details-Info-con">
                      <div className="Review-Price-Section-Details-Info Review-Price-Section-Details-Info-border">
                        <h3>Weekdays Price</h3>
                        <h4>₹{week_days || "N/A"}</h4>
                      </div>
                      <div className="Review-Price-Section-Details-Info Review-Price-Section-Details-Info-border">
                        <h3>Taxes (18%)</h3>
                        <h4>₹{gst || "N/A"}</h4> {/* Display GST here */}
                      </div>
                      <div className="Review-Price-Section-Details-Info">
                        <h3>Total Amount</h3>
                        <h4>₹{totalAmount || "N/A"}</h4> {/* Display Total Amount */}
                      </div>
                    </div>
                  </div>
                  <div className="Booking-btn-Con">
                    <Button type="primary" htmlType="submit" loading={loading}>
                      Place Your Order and Pay
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
      <FooterSec />
    </>
  );
};

export default ReviewBooking;
