import React from 'react';
import "../Congratulation/BookingConfirm.css";
import Image from "../../Images/deck-chair.png";
import { Link } from 'react-router-dom';

const BookingConfirm = () => {
    return (
        <>
            <section className='booking-confirm-con'>
                <div className='container'>
                    <div className='booking-confirm-main-con'>
                        <div className='booking-confirm-deatils'>
                            <div className='booking-confirm-deatils-Head-con'>
                                <h2>Royal</h2>
                                <div className='Booking-Head-Image'>
                                    <img src={Image} alt='best villa in Mahabaleshwar' />
                                </div>
                                <h2>Resort</h2>
                            </div>
                            <h3>congratulation</h3>
                            <h4>booking confirmed</h4>
                            <h5>Booking details has been shared on your registered email id</h5>
                            <div className='Booking-confirm-btn'>
                                <Link to="/">
                                    <button>
                                        ok
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default BookingConfirm