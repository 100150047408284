import React, { useEffect, useState } from 'react';
import "../Our Packages/Packages.css";
import "../Our Packages/PackagesCard.css";
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

import { FaStar } from "react-icons/fa6";
import { Link } from 'react-router-dom';
import royal from "../../Images/Aayush Images/royal.png";

const Packages = () => {
    const [rooms, setRooms] = useState([]);

    useEffect(() => {
        const fetchRooms = async () => {
            try {
                const response = await fetch('https://www.skdm.in/server/royal/room.php?action=roomFetch');
                const data = await response.json();
                setRooms(data.list);
            } catch (error) {
                console.error("Error fetching room data:", error);
            }
        };

        fetchRooms();
    }, []);

    return (
        <>
            <section className='Home-Section-packages'>
                <div className='Home-Section-packages-Head'>
                    <h2 className='animate__zoomIn'>Our Packages
                        <div className='HeadImageoverlay'>
                            <img src={royal} alt='resort in mahabaleshwar' />
                        </div>
                    </h2>
                    <div className='Home-Section-packages-Head-para'>
                        <p>We Provide Top Class Facility <br /> Expecially For You</p>
                    </div>
                </div>

                <div className='Home-Section-packages-Main-con'>
                    <Swiper
                        spaceBetween={30}
                        centeredSlides={true}
                        pagination={{
                            clickable: true,
                        }}
                        navigation={true}
                        modules={[Pagination, Navigation]}
                        className="mySwiper"
                    >
                        {rooms.map(room => (
                            <SwiperSlide key={room.room_id}>
                                <div className='Home-Section-packages-card-body'>
                                    <div className='Home-Section-packages-card-Image-con'>
                                        <img src={room.images[0]?.image_view} alt={room.room_name}/>
                                        <div className='Home-Section-Packages-Card-Details-con'>
                                            <h3>{room.room_name}</h3>
                                            <div className='Home-Section-Packages-Card-Details-Price-Days'>
                                                <div className='row'>
                                                    <div className='col-md-5'>
                                                        <div className='Home-Packages-Card-Info'>
                                                            <h4>Capacity: {room.room_qty}</h4>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-7'>
                                                        <div className='Home-Packages-Card-Info'>
                                                            <h4>Price: ₹{room.week_days} Night</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='Home-Packages-Card-Start-con'>
                                                {/* Example stars for rating */}
                                                {Array(5).fill().map((_, index) => (
                                                    <div className='Home-Packages-Card-Start starcolor' key={index}>
                                                        <FaStar />
                                                    </div>
                                                ))}
                                            </div>
                                            {/* <p>{room.description || "No description available."}</p> */}
                                            <div className='Login-Btn'>
                                                <Link to={`/RoomDetailsPage/${room.room_id}`}>
                                                    <button>
                                                        Book Now
                                                    </button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </section>
        </>
    );
}

export default Packages;
